import React, { useState, useEffect } from 'react';
import { Select, Stack } from '@chakra-ui/react';
import Tabel from '../commons/Tabel';
import axios from 'axios';

function AdminTransaction() {
  const [listReload,setListReload]=useState(false);
  const [chartDataKey,setChartDataKey]=useState('all');
  const [listData,setListData]=useState([]);
  const dropdown2=['Expense','Income','All'];

  useEffect(()=>{
    setListReload(true);
    axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/transaction`).then((response)=>{
      setListReload(false);
      if (chartDataKey === 'Income') {
        setListData(
          response.data.data.filter((dt) => dt.transaction_type === 'Income')
        );
      } else if (chartDataKey === 'Expense') {
        setListData(
          response.data.data.filter((dt) => dt.transaction_type === 'Expense')
        );
      } else {
        setListData(response.data.data);
      }
    });
  },[chartDataKey]);

  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />

      <Stack spacing={3} w='14vw'>
        <Select placeholder='Select Transaction' size='md'
          onChange={(e)=>{
            setChartDataKey(e.target.value === "Expense" ? 'Expense' : e.target.value === "Income"  ? 'Income' : 'all');
          }}
          style={{ width: "200px", padding: "0px 10px", height: "50px", margin: "0px 0px 0px"}}
        >
          {dropdown2.map((item)=>(
            <option value={item}>{item}</option>
          ))}
        </Select>
      </Stack>

      <Tabel listData={listData} header_data={[ 'phone_no', 'transaction_type','amount','category','purpose' ]} listReload={listReload}/>
    </div>
  );
}

export default AdminTransaction;
