import React, { useEffect, useState } from "react";
import axios from 'axios'
import { ViewOffIcon } from "@chakra-ui/icons";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useApiCallHook from "../Helpers/useApiCallHook";
import { CheckIcon, CloseIcon, LinkIcon } from "@chakra-ui/icons";
import { Spinner ,Flex } from '@chakra-ui/react'
import SearchBar from "./SearchBar";
function UserDetailPage() {
  const [userSearch, setUserSearch] = useState("");

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  // const isMobile = window.innerWidth < 768;
const { number } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading,setLoading]=useState(false)


  const tableHeaderStyle = {
    backgroundColor: "#f2f2f2",
    padding: "12px",
    border: "1px solid #ddd",
  };

  const tableCellStyle = {
    padding: "12px",
    border: "1px solid #ddd",
  };
useEffect(()=>{
  setLoading(true)

 axios.get(`${process.env.REACT_APP_API_END_POINT}/user/detail/${number}`).then((response)=>{
  setLoading(false)

   setUser(response.data)
 })
},[])

  return (
    <>
      <div style={{ maxHeight: "100vh", minHeight: "100vh", width: "100vw" }}>
      <div
          style={{
            minHeight: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h1
              style={{
                color: "#333",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              User : {user?.data?.user_name ? user?.data?.user_name :
              (
              // <Flex height="100vh" justify="center" align="center">
      <Spinner size="md" />
    // </Flex>
  )
              }
            </h1>
           
          </div>
        <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />
      <div
        style={{
          padding: "8px 20px 24px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchBar setSearchValues={setUserSearch} />
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div style={{ marginRight: "10px" }}>
            <label style={{ marginRight: "10px" }}>From Date:</label>
            <input
              type="date"
              id="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div style={{ marginRight: "10px" }}>
            <label style={{ marginRight: "10px" }}>To Date:</label>
            <input
              type="date"
              id="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <button
            style={{
              padding: "8px 16px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            // onClick={fetchAndSetUserByDate}
          >
            Fetch
          </button>
        </div>
      </div>

   
     
    </div>

        
        </div>
    </div>
    
    </>
  );
}

export default UserDetailPage;
