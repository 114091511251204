import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { Select, Spinner, Stack } from "@chakra-ui/react";

const Tabel = ({ header_data, listData, listReload, currency }) => {
  const headerList = {
    transaction_type: "Transaction Type",
    amount: "Amount",
    category: "Transaction Category",
    company_name: "Company Name",
    item: "Item",
    phone_no: "Phone number",
    quantity: "Quantity",
    purpose: "Purpose",
  };
  return (
    <div>
      <TableContainer>
        {listReload ? (
          <>
            <div className="flex flex-col items-center justify-center h-screen w-screen ">
              <Spinner size="sm" />
            </div>
          </>
        ) : (
          <Table size="sm">
            <Thead>
              <Tr>
                {header_data.map((item) => (
                  <Th key={item}>{headerList[item]}</Th>
                ))}
              
              </Tr>
            </Thead>
            <Tbody>
              {listData.length > 0 &&
                listData.map((item, index) => (
                  <Tr key={index}>
                    {header_data.map((key, subIndex) => (
                      <Td key={subIndex}>
                        {key === 'amount' ? (
                          `${currency ? currency : 'R'} ${item[key]}`
                        ) : (
                          item[key]
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default Tabel;
