import React from "react";
import SearchBar from "./SearchBar";
import UserCard from "./UserCard";
import useHelperFunctionsHook from "../Helpers/useHelperFunctionsHook";
import useApiCallHook from "../Helpers/useApiCallHook";
import {Chart as ChartJS} from 'chart.js/auto'
import {Bar,Pie} from "react-chartjs-2"
import axios from 'axios'
import { useState, useEffect } from "react";
import { Select, Spinner, Stack } from '@chakra-ui/react'
import { format, subMonths, subWeeks } from 'date-fns';

import {
  CheckIcon,
  CloseIcon,
  HamburgerIcon,
  LinkIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Modal,Heading,
  Box,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { MdPermMedia } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import DashBoardBox from "./DashBoardBox";
import useToastHook from "../Helpers/useToastHook";
import Tabel from "../commons/Tabel";
import { saveAs } from 'file-saver';

function User({ currency }) {
  const navigate = useNavigate();
  const { ToastService } = useToastHook();
  const { getUser, getWhatsappLogs, getUserDashboardData, getUserLocations } =
    useApiCallHook();
  const [userSearch, setUserSearch] = useState("");
const dropdown1=["Monthly",'Weekly']
const dropdown2=["Expense",'Income','All']
const { number } = useParams();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectDurationType,setSelectDurationType]=useState(null)
  const [selectExpanseType,setSelectExpanseType]=useState(null)
  const [chartData,setChartData]=useState(null)
  const [reload,setReload]=useState(false)
  const [listReload,setListReload]=useState(false)
  const [chartDataKey,setChartDataKey]=useState("all")
  const [listData,setListData]=useState([])
  const [goalsData,setGoalsData]=useState(null)
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const [label, setLabel] = useState([]);
  const [expenseData, setExpenseData] = useState([23,2323,2354,543,]);
  const [incomeData, setIncomeData] = useState([]);
  const [sales, setSales] = useState(0);
  const [costOfSales, setCostOfSales] = useState(0);
  const [rent, setRent] = useState(0);
  const [utilities, setUtilities] = useState(0);
  const [marketing, setMarketing] = useState(0);
  const [equipment, setEquipment] = useState(0);
  const [bankFees, setBankFees] = useState(0);
  const [telephone, setTelephone] = useState(0);
  const [salaries, setSalaries] = useState(0);
  const [travelling, setTravelling] = useState(0);
  const [startUp, setStartUp] = useState(0);
  const [other, setOther] = useState(0);

  const pieChartData = {
    labels: ['Sales', 'Cost of sales', 'Rent', 'Telephone', 'Utilities', 'Marketing', 'Equipment', 'Bank fees', 'Salaries', 'Travelling', 'Start up', 'Other'],
    datasets: [
      {
        label: 'Sum',
        data: [sales, costOfSales, rent, telephone, utilities, marketing, equipment, bankFees, salaries, travelling, startUp, other],
        backgroundColor: [
          'rgba(151, 154, 155, 0.8)',
          'rgba(147, 114, 100, 0.8)',
          'rgba(255, 163, 68, 0.8)',
          'rgba(255, 220, 73, 0.8)',
          'rgba(77, 171, 154, 0.8)',
          'rgba(82, 156, 202, 0.8)',
          'rgba(154, 109, 215, 0.8)',
          'rgba(226, 85, 161, 0.8)',
          'rgba(255, 115, 105, 0.8)',
          'rgba(218, 112, 214, 0.8)',
          'rgba(255, 215, 0, 0.8)',
          'rgba(255, 140, 0, 0.8)',
        ],
        borderColor: [
          'rgba(151, 154, 155, 1)',
          'rgba(147, 114, 100, 1)',
          'rgba(255, 163, 68, 1)',
          'rgba(255, 220, 73, 1)',
          'rgba(77, 171, 154, 1)',
          'rgba(82, 156, 202, 1)',
          'rgba(154, 109, 215, 1)',
          'rgba(226, 85, 161, 1)',
          'rgba(255, 115, 105, 1)',
          'rgba(218, 112, 214, 1)',
          'rgba(255, 215, 0, 1)',
          'rgba(255, 140, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const data={
      labels: label,

      datasets:[
        {
          label:"Expense",
          data: expenseData
        },
        {
          label:"Income",
          data: incomeData
        }

      ]
     }
useEffect(()=>{
  axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/${chartDataKey}/${number}`).then((response)=>{
  setListReload(false)
setListData(response.data.data)

setSales(response.data.data
  .filter((data) => data.category === 'sales')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setRent(response.data.data
  .filter((data) => data.category === 'rent')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setMarketing(response.data.data
  .filter((data) => data.category === 'marketing')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setCostOfSales(response.data.data
  .filter((data) => data.category === 'costofsales')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setUtilities(response.data.data
  .filter((data) => data.category === 'utilities')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setEquipment(response.data.data
  .filter((data) => data.category === 'equipment')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setBankFees(response.data.data
  .filter((data) => data.category === 'bank_fees')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setTelephone(response.data.data
  .filter((data) => data.category === 'telephone')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setSalaries(response.data.data
  .filter((data) => data.category === 'salaries')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setTravelling(response.data.data
  .filter((data) => data.category === 'travelling')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setStartUp(response.data.data
  .filter((data) => data.category === 'startup')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

setOther(response.data.data
  .filter((data) => data.category === 'other')
  .reduce((sum, item) => sum + parseInt(item.amount), 0)
);

if (selectDurationType === 'Weekly') {
  const weeks = Array.from({ length: 7 }, (_, i) => 
    format(subWeeks(new Date(), i), 'MM/dd/yyyy')
  ).reverse();
  setLabel(weeks);

  const expensesData = [];
  const incomesData = [];
  weeks.forEach(week => {
    const expenses = response.data.data
      .filter(dt => dt.transaction_type === 'Expense' && format(new Date(dt.createdAt), 'MM/dd/yyyy') === week)
      .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
    expensesData.push(expenses);

    const income = response.data.data
      .filter(dt => dt.transaction_type === 'Income' && format(new Date(dt.createdAt), 'MM/dd/yyyy') === week)
      .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
    incomesData.push(income);
  });

  setExpenseData(expensesData);
  setIncomeData(incomesData);

} else {
  const months = Array.from({ length: 7 }, (_, i) => 
    format(subMonths(new Date(), i), 'MMMM')
  ).reverse();
  setLabel(months);

  const expensesData = [];
  const incomesData = [];
  months.forEach(month => {
    const expenses = response.data.data
      .filter(dt => dt.transaction_type === 'Expense' && format(new Date(dt.createdAt), 'MMMM') === month)
      .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
    expensesData.push(expenses);

    const income = response.data.data
      .filter(dt => dt.transaction_type === 'Income' && format(new Date(dt.createdAt), 'MMMM') === month)
      .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
    incomesData.push(income);
  });

  setExpenseData(expensesData);
  setIncomeData(incomesData);
}
  })
},[
  selectDurationType,
  selectExpanseType
])

const handleButtonClick = () => {
  setDropdownVisible(!isDropdownVisible);
};

const handleItemClick = (item) => {
  fetchDataAndDownloadCSV(item);
  setDropdownVisible(false);
};

const fetchDataAndDownloadCSV = async (item) => {
  try {
    let response;
    if (item == 'transaction') {
      response = await fetch(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/all/${number}`);
    } else if (item == 'goals') {
      response = await fetch(`${process.env.REACT_APP_API_END_POINT}/transactions/goals/${number}`);
    } else if (item == 'invoice') {
      response = await fetch(`${process.env.REACT_APP_API_END_POINT}/transactions/invoice/${number}`);
    }
    const data = await response.json();
    const csv = convertToCSV(data.data);
    const dateNow = Date.now();
    downloadCSV(csv, `${dateNow}_${item}.csv`);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const convertToCSV = (data) => {
  const keys = [
    'phone_no',
    'type',
    'amount',
    'expense_goal',
    'expense_p',
    'income_goal',
    'income_p',
    'item',
    'quantity',
    'transaction_type',
    'purpose',
    'category',
    'createdAt'
  ].filter(key => key in data[0]);

  const array = [keys];
  data.forEach(item => {
    const row = keys.map(key => item[key] || ''); 
    array.push(row);
  });

  return array.map(row => row.join(',')).join('\n');
};

const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, filename);
};


useEffect(()=>{
  axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/goals/${number}`).then((response)=>{
    setGoalsData(response.data.data[0])
  })
},[]);

useEffect(()=>{
const token=localStorage.getItem('token')
if(!token){
  navigate(`/user/${number}`)
} else {
  const user_name = localStorage.getItem('user_name');
  navigate(`/user/dashboard/${user_name}`)
}
},[])

  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />
      <button
          style={{
            marginLeft: '10px',
            padding: '5px 10px',
            borderRadius: '4px',
            border: '1px solid grey',
          }}
          onClick={handleButtonClick}
        >Download CSV</button>
      {isDropdownVisible && (
        <div
          style={{
            position: 'absolute',
            left: '10%',
            top: '25%',
            backgroundColor: 'white',
            border: '1px solid grey',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
          }}
        >
          <div
            style={{ padding: '8px 12px', cursor: 'pointer' }}
            onClick={() => handleItemClick('transaction')}
          >
            Transactions
          </div>
          <div
            style={{ padding: '8px 12px', cursor: 'pointer' }}
            onClick={() => handleItemClick('invoice')}
          >
            Invoices
          </div>
          <div
            style={{ padding: '8px 12px', cursor: 'pointer' }}
            onClick={() => handleItemClick('goals')}
          >
            Goals
          </div>
        </div>
      )}
      <div
        style={{
          padding: "8px 20px 24px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
           <Stack spacing={3} w='14vw'
           >

  <Select placeholder='Select Duration' size='md'
  onChange={(e)=>{
    setSelectDurationType(e.target.value)
  }}
  style={{ width: '200px', height: '50px', padding: "0px 10px", borderRadius: "10px" }}
  >

    {dropdown1.map((item)=>(
      <option value={item}>{item}</option>
    ))}

  </Select>



</Stack>
  <Stack spacing={3} w='14vw'>

  <Select placeholder='Select Transaction' size='xl'
   onChange={(e)=>{
    setSelectExpanseType(e.target.value)
    setChartDataKey(e.target.value==="Expense" ? 'Expense' : e.target.value ==="Income"  ? 'Income' : 'all')
  }} style={{ width: '200px', height: '50px', padding: "0px 10px", borderRadius: "10px" }}
  >

    {dropdown2.map((item)=>(
      <option value={item}>{item}</option>
    ))}

  </Select>



</Stack>
      </div>
{
  reload ?(<>
  <div className='flex flex-col items-center justify-center h-screen w-screen '>
     <Spinner size="sm" /> 
  </div>
  </>) : (<>
    <div style={{ display: 'flex', width: '100%' }}>
      {data &&
        <div style={{ flex: 3 }}>
          <Bar data={data} />
        </div>
      } 
      {pieChartData && 
      <div style={{ flex: 1, margin: 'auto 10px' }}>
        <Pie data={pieChartData} />
      </div>
      }
      </div>
  </>)
}
<div className="my-5">


<Box p={5} maxW="xl" borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="lg" mx="auto" my={5}>
        <Heading as="h4" size="lg" mb={8}>
          Your Goals
        </Heading>
        <VStack align="start">
        <Box display="flex" justifyContent="space-between">
  <Box mx={5}>
    <Text fontSize="md" fontWeight="bold">
      Expense Goal
    </Text>
    <Text fontSize="sm">{currency} {goalsData?.expense_goal ? goalsData?.expense_goal : 0}</Text>
  </Box>
  <Box>
    <Text fontSize="md" fontWeight="bold">
      Income Goal
    </Text>
    <Text fontSize="sm">{currency} {goalsData?.income_goal ? goalsData?.income_goal : 0}</Text>
  </Box>
</Box>
        </VStack>
      </Box>
</div>
<div my={5}>

  

<Tabel listData={listData} header_data={['transaction_type','amount','category','purpose']} listReload={listReload} currency={currency}/>
</div>

    </div>
   
   
  );
}

export default User;
