import React, { useEffect, useState } from "react";
import { CloseIcon, CheckIcon, LinkIcon } from "@chakra-ui/icons";
import useApiCallHook from "../Helpers/useApiCallHook";
import DashBoardBox from "./DashBoardBox";
import useToastHook from "../Helpers/useToastHook";
import SearchBar from "./SearchBar";
import { useParams } from "react-router-dom";
import axios from 'axios'
import Tabel from "../commons/Tabel";

function SubscriptionReport({ currency }) {
  const [user, setUser] = useState("");
const { number } = useParams();
 const [listReload,setListReload]=useState(false)
  const [listData,setListData]=useState([])

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
useEffect(()=>{
setListReload(true)
   axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/invoice/${number}`).then((response)=>{
  setListReload(false)
setListData(response.data.data)
  })
},[]);

  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />
      <div
        style={{
          padding: "8px 20px 24px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
      </div>

   
   <Tabel listData={listData} header_data={['item', 'quantity', 'amount']} listReload={listReload} currency={currency}/>
    </div>
  );
}

export default SubscriptionReport;
