import React, { useState,useEffect } from 'react';
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  Spinner, 
  useToast ,Heading, Center 
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

const UserLogin = () => {
  const [number, setNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(()=>{
    if(localStorage.getItem('token')){
      const user_name = localStorage.getItem('user_name');
      navigate(`/user/dashboard/${user_name}`);
    }
  },[]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      navigate(`/user/${number}`);
    } catch (error) {
      toast({
        title: "Login failed.",
        description: "Invalid username or password.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Center h="20vh">
        <Heading as="h1" size="xl" fontWeight="bold">
            Tetafi User Panel
        </Heading>
      </Center>
      <Box 
        maxW="sm" 
        borderWidth="1px" 
        borderRadius="lg" 
        overflow="hidden" 
        p="6"
        m="auto"
        mt="10"
      >
        <form onSubmit={(e)=> handleSubmit(e)}>
          <FormControl id="user_name" mb="4">
            <FormLabel>Username</FormLabel>
            <Input
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder='Enter phone number with country code'
              required
            />
          </FormControl>
          <Button type="submit" colorScheme="teal" width="full" mt="4" disabled={loading}>
            {loading ? <Spinner size="sm" /> : 'Continue'}
          </Button>
        </form>
      </Box>
    </>
  );
};

export default UserLogin;
