import React, { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const RandomUrl = () => {
  const { randomCode } = useParams();

  const getSessionUrl = async () => {
    const sessionUrl = await axios.get(`${process.env.REACT_APP_API_END_POINT}/payment/${randomCode}/session-url`);
    if (sessionUrl.data) {
      window.location.href = sessionUrl.data;
    } else {
      window.location.href = 'https://tetafi.co.za';
    }
  }

  useEffect(() => {
    getSessionUrl();
  }, []);

  return (
    <></>
  );
}

export default RandomUrl;
