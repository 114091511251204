import React, { useEffect, useState } from 'react';
import { Box, Button, Input, FormLabel, FormControl, Select, Image, useTheme } from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import axios from 'axios';

function Settings() {
  const theme = useTheme();
  const [user, setUser] = useState({});
  const phone_no = localStorage.getItem('user_name');
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    phone_no,
    company_name: '',
    company_logo: '',
    company_logo_name: '',
    currency: 'R',
    customization: '',
  });

  useEffect(() => {
    const getUser = async () => {
      const userDetails = await axios.get(`${process.env.REACT_APP_API_END_POINT}/user/detail/${phone_no}`);
      const data = userDetails.data.data;
      setFormData({
        ...formData,
        name: data.name,
        email: data.email,
        company_name: data.company_name,
        customization: data.customization,
        company_logo: `${process.env.REACT_APP_MEDIA_URL}/media/logo/${data.company_logo}`,
        company_logo_name: data.company_logo,
      });
      setUser(userDetails.data.data);
    }
    getUser();
  }, []);

  const customizations = [
    { text: 'Retail (e-commerce and spaza stores)' },
    { text: 'Food and Beverage (restaurants, cafes, catering)' },
    { text: 'Healthcare (clinics, wellness centers, pharmacies)' },
    { text: 'Financial Services (banking, financial advisory, insurance)' },
    { text: 'Information Technology (software development, IT support, cybersecurity)' },
    { text: 'Marketing and Advertising (digital marketing, branding, public relations)' },
    { text: 'Education and Training (tutoring, professional training, online courses)' },
    { text: 'Real Estate (property management, real estate agencies)' },
    { text: 'Transportation and Logistics (freight, shipping, delivery services)' },
    { text: 'Hospitality (hotels, travel agencies, event planning)' },
    { text: 'Other' },
  ]

  const handleInputChange = (e) => {
    console.log('Target: ', e.target.value);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({ ...formData, company_logo: event.target.result, company_logo_name: file.name });
      };
      reader.readAsDataURL(file);

      const data = new FormData();
      data.append('company_logo', file);
      const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/user/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.patch(`${process.env.REACT_APP_API_END_POINT}/user/${formData.phone_no}/update`, {
      name: formData.name,
      email: formData.email,
      company_logo: formData.company_logo_name,
      company_name: formData.company_name,
      currency: formData.currency,
      customization: formData.customization,
    });

    alert('Settings saved successfully');
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setFormData({ ...formData, company_logo: '', company_logo_name: '' });
  }

  return (
    <div>
      <Box p="4" style={{
        border: '1px solid black',
        borderRadius: '6px',
        margin: '20px auto',
        maxWidth: '600px',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <h1 style={{ padding: '20px', fontWeight: 'bold', fontSize: '30px'}}>Settings</h1>
          <Box display="flex" justifyContent="flex-end" mb="4">
            {formData.company_logo ? (
              <Image src={formData.company_logo} alt="Company Logo" boxSize="100px" objectFit="cover" />
            ) : (
              <div></div>
            )}
          </Box>
        </div>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" mb="4">
            <FormLabel>Email Address</FormLabel>
            <Input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
          </FormControl>
          <FormControl id="name" mb="4">
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
          </FormControl>
          <FormControl id="phone_no" mb="4">
            <FormLabel>Phone Number</FormLabel>
            <Input type="tel" name="phone_no" value={phone_no} disabled />
          </FormControl>
          <FormControl id="company_name" mb="4">
            <FormLabel>Company Name</FormLabel>
            <Input type="text" name="company_name" value={formData.company_name} onChange={handleInputChange} required />
          </FormControl>
          <FormControl id="company_logo" mb="4">
            <FormLabel>Company Logo</FormLabel>
            {
              formData.company_logo
                ? <div style={{
                    border: `1px solid`,
                    borderRadius: '6px',
                    borderColor: theme.colors.chakraBorder,
                    height: '2.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}>
                    <p>{formData.company_logo_name}</p>
                    <button onClick={handleDelete}><MdDelete size={20} /></button>
                  </div>
                : <Input type="file" accept=".png, .jpg, .jpeg" onChange={handleFileChange} style={{ paddingTop: '5px'}} required />
            }
          </FormControl>
          <FormControl id="currency" mb="4">
            <FormLabel>Currency</FormLabel>
            <Input type="tel" name="currency" value={'South Africa - ZAR (R)'} disabled />
          </FormControl>
          <FormControl id="customization" mb="4">
            <FormLabel>Customization</FormLabel>
            <Select name="customization" value={formData.customization} onChange={handleInputChange} required>
              <option value="">Describe what your business does</option>
              {customizations.map((customization, index) => (
                <option key={index} value={customization.text}>
                  {customization.text}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" colorScheme="blue">Save Settings</Button>
        </form>
      </Box>
    </div>
  );
}

export default Settings;
