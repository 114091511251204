import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tabel from '../commons/Tabel';

function AdminInvoice() {
  const [listReload,setListReload]=useState(false);
  const [listData,setListData]=useState([]);

  useEffect(()=>{
    setListReload(true);
    axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/invoice`).then((response)=>{
      setListReload(false);
      setListData(response.data.data);
      console.log('Data: ', response.data.data);
    });
  },[]);

  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />
      <div
        style={{
          margin: "0 40px",
        }}
      >
        <Tabel listData={listData} header_data={['phone_no', 'item', 'quantity', 'amount' ]} listReload={listReload}/>
      </div>
    </div>
  );
}

export default AdminInvoice;
