import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../App";
import UserDetailPage from "../Components/UserDetailPage";
import LoginPage from "../Components/LoginPage";
import AdminLogin from "../Components/AdminLogin";
import AdminDashboard from "../Components/AdminDashboard";
import UserLogin from "../Components/UserLogin";
import Settings from "../Components/Settings";
import PaymentForm from "../Components/Payment";
import RandomUrl from "../Components/RandomUrl";
// import Login from "../Components/LoginPage";

export default function PageRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/login" element={<AdminLogin />}></Route>
        <Route path="/admin/dashboard" element={<AdminDashboard />}></Route>
        <Route path="/user" element={<UserLogin />}></Route>
        <Route path="/user/:number" element={<LoginPage />}></Route>
        <Route path="/user/dashboard/:number" element={<App />}></Route>
        <Route path="/*" element={
          localStorage.getItem('user_name') 
          ? <App /> 
          : localStorage.getItem('token') ? <AdminDashboard /> : <LoginPage />
        }></Route>
        <Route path="/admin/*" element={localStorage.getItem('token') ? <AdminLogin /> : <AdminDashboard />}></Route>
        <Route path="/user/:number/settings" element={<Settings />}></Route>
        <Route path="/user/payment" element={<PaymentForm />}></Route>
        <Route path="/payment/:randomCode" element={<RandomUrl />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
