import React, { useEffect, useState } from "react";
import useApiCallHook from "../Helpers/useApiCallHook";
import DashBoardBox from "./DashBoardBox";
import useToastHook from "../Helpers/useToastHook";
import SearchBar from "./SearchBar";
import Tabel from "../commons/Tabel";
import axios from 'axios'
import { Select, Spinner, Stack } from '@chakra-ui/react'
import { useParams } from "react-router-dom";


function Analytics({ currency }) {
  const [userSearch, setUserSearch] = useState("");
  const { number } = useParams();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [listReload,setListReload]=useState(false)
  const [chartDataKey,setChartDataKey]=useState("all")
  const [listData,setListData]=useState([])
  const dropdown2=["Expense",'Income','All']


useEffect(()=>{
setListReload(true)
   axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/${chartDataKey}/${number}`).then((response)=>{
  setListReload(false)
setListData(response.data.data)
  })

},[
  
  chartDataKey
])
  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />

      <Stack spacing={3} w='14vw'>

  <Select placeholder='Select Transaction' size='md'
  onChange={(e)=>{
    setChartDataKey(e.target.value==="Expense" ? 'Expense' : e.target.value ==="Income"  ? 'Income' : 'all')
  }}
  style={{ width: "200px", padding: "0px 10px", height: "50px", margin: "0px 0px 0px"}}
  >

    {dropdown2.map((item)=>(
      <option value={item}>{item}</option>
    ))}

  </Select>



</Stack>

   <Tabel listData={listData} header_data={['transaction_type','amount','category','purpose']} listReload={listReload} currency={currency}/>
     
    </div>
  );
}

export default Analytics;
