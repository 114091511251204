import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  Spinner, 
  useToast ,Heading, Center 
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import mainLogo from '../assets/main-logo.png';

const AdminLogin = () => {
  const [user_name, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/admin/dashboard');
    } else {
      navigate('/admin/login');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/admin/login`, {
        username: user_name,
        password
      });
      localStorage.setItem('token', response.data.data.token);
      navigate(`/admin/dashboard`);
      toast({
        title: "Admin Login successful.",
        description: "You have successfully logged in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Admin Login failed.",
        description: "Invalid username or password.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="img-box" style={{ margin: "50px 0px 0px"}}>
        <img src={mainLogo} alt="something went wrong" style={{ height: "auto", width: "120px", objectFit: "cover", margin: "0 auto" }}/>
      </div>
      <Center h="20vh"> {/* Centering content vertically */}
        <Heading as="h1" size="xl" fontWeight="bold">
            Tetafi Admin Panel
        </Heading>
      </Center>
      <Box
        maxW="sm" 
        borderWidth="1px" 
        borderRadius="lg" 
        overflow="hidden" 
        p="6"
        m="auto"
        mt="10"
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl id="user_name" mb="4">
            <FormLabel>Username</FormLabel>
            <Input
              type="text" 
              value={user_name}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </FormControl>
          <FormControl id="user_name" mb="4">
            <FormLabel>Password</FormLabel>
            <Input
              type="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormControl>
      
          <Button type="submit" colorScheme="teal" width="full" mt="4" disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Login"}
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default AdminLogin;
