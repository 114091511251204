import { ViewOffIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminLogin from './AdminLogin';
import Dashboard from './AdminDashboardBox';
import Transaction from './AdminTransaction';
import Invoice from './AdminInvoice';
import mainLogo from '../assets/main-logo.png'

function AdminDashboard() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [userLogin, setUserLogin] = useState(true);
  const tabNames = ["Dashboard", "Transaction", "Invoice"];
  const [index, setIndex] = useState(0);

  const tabIndex = {
    0: <Dashboard />,
    1: <Transaction />,
    2: <Invoice />,
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/admin/login');
    } else {
      navigate('/admin/dashboard');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    <header style={{ background: "#e6f0f5", position: "relative", height: "80px", width: "100%", display: "flex", alignItems: "center"}}>
        <div className="img-box" style={{ position: "absolute", left: "20px"}}>
        <img src={mainLogo} alt="something went wrong" style={{ height: "auto", width: "120px", objectFit: "cover", margin: "0 auto" }}/>
        </div>
        <button
          style={{
            padding: "8px 20px",
            border: "1px solid gray",
            borderRadius: "8px",
            position: "absolute",
            right: "20px"
          }}
          onClick={() => {
            localStorage.removeItem("token");
            navigate(`/admin/login`);
            setUserLogin(false);
          }}
        >
          Logout
        </button>
      </header>
      {userLogin ? (
        <div style={{ maxHeight: "100vh", minHeight: "100vh", width: "100vw" }}>
          <div
            style={{
              minHeight: "100vh",
              width: "100vw",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <div
              style={{
                minWidth: "100%",
                maxWidth: "80vw",
                border: "1px solid darkgray",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: "12px",
                  display: "flex",
                }}
              >
                {window.innerWidth < 767 ? (
                  <select onChange={(e) => setIndex(e.target.value)} value={index}>
                    {tabNames.map((tab, i) => (
                      <option key={tab} value={i}>
                        {tab}
                      </option>
                    ))}
                  </select>
                ) : (
                  tabNames.map((tab, i) => (
                    <button
                      key={tab}
                      className="tab-button"
                      onClick={() => setIndex(i)}
                    >
                      {tab}
                    </button>
                  ))
                )}
              </div>

              <div
                style={{
                  flex: "1",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <div style={{ padding: "8px" }}>{tabIndex[index]}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AdminLogin />
      )}
    </>
  );
}

export default AdminDashboard;
