import { ViewOffIcon } from "@chakra-ui/icons";
import Invoice from "./Components/Invoice";
import Dashboard from "./Components/Dashboard";
import { useEffect, useState } from "react";
import useApiCallHook from "./Helpers/useApiCallHook";
import Transation from "./Components/Transation";
import DashBoardBox from "./Components/DashBoardBox";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Heading,
  Center,
} from "@chakra-ui/react";
import Settings from "./Components/Settings";
import mainLogo from './assets/main-logo.png';
import './index.css';

function App() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const { number } = useParams();
  const { getDashboardStats } = useApiCallHook();
  const [dashboardStats, setDashboardStats] = useState();
  // const isMobile = window.innerWidth < 768;
  const tabNames = ["Dashboard", "Transaction", "Invoice", "Settings"];
  const token = localStorage.getItem("token");
  const toast = useToast();
  const [userLogin, setUserLogin] = useState(true);
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState('');
  // useEffect(() => {
  //   if (!token) {
  //     // axios
  //     //   .get(`${process.env.REACT_APP_API_END_POINT}/user/otp/${number}`)
  //     //   .then((resData) => {
  //     //     setLoading(true);
  //     //     toast({
  //     //       title: "Login successful.",
  //     //       description: "You have successfully logged in.",
  //     //       status: "success",
  //     //       duration: 5000,
  //     //       isClosable: true,
  //     //     });
  //     //   });
  //   } else {
  //     setUserLogin(true);
  //   }
  // }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_END_POINT}/user/detail/${number}`).then((response) => {
      setCurrency(response.data.data.currency);
    });
  }, []);

  const tabIndex = {
    0: <Dashboard currency={currency} />,
    1: <Transation currency={currency} />,
    2: <Invoice currency={currency} />,
    3: <Settings />,
  };

  return (
    <div>
      <header style={{ background: "#e6f0f5", position: "relative", height: "80px", width: "100%", display: "flex", alignItems: "center"}}>
        <div className="img-box" style={{ position: "absolute", left: "20px"}}>
        <img src={mainLogo} alt="something went wrong" style={{ height: "auto", width: "120px", objectFit: "cover", margin: "0 auto" }}/>
        </div>
        <button
                  style={{
                    padding: "8px 20px",
                    border: "1px solid gray",
                    borderRadius: "8px",
                    position: "absolute",
                    right: "20px"
                  }}
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_name");
                    navigate(`/user/${number}`);
                  }}
                >
                  Logout
        </button>
      </header>
      {userLogin ? (
        <div style={{ maxHeight: "100vh", minHeight: "100vh", width: "100vw" }}>
          <div
            style={{
              minHeight: "100vh",
              width: "100vw",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <div
              style={{
                minWidth: "100%",
                maxWidth: "80vw",
                border: "1px solid darkgray",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: window.innerWidth < 767 ? "column" : "row",
                }}
              >
                {window.innerWidth < 767 ? (
                  <select onChange={(e) => setIndex(e.target.value)} value={index}>
                    {tabNames.map((tab, i) => (
                      <option key={tab} value={i}>
                        {tab}
                      </option>
                    ))}
                  </select>
                ) : (
                  tabNames.map((tab, i) => (
                    <button
                      key={tab}
                      className="tab-button"
                      onClick={() => setIndex(i)}
                    >
                      {tab}
                    </button>
                  ))
                )}
              </div>
    
              <div
                style={{
                  flex: "1",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <div style={{ padding: "8px" }}>{tabIndex[index]}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <>
            <Center h="20vh">
              {" "}
              {/* Centering content vertically */}
              <Heading as="h1" size="xl" fontWeight="bold">
                Tetafi User Panel
              </Heading>
            </Center>
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p="6"
              m="auto"
              mt="10"
            >
              <form>
                <FormControl id="user_name" mb="4">
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    type="text"
                    value={user_name}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </FormControl>
             
                <Button
                  type="submit"
                  colorScheme="teal"
                  width="full"
                  mt="4"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Otp"}
                </Button>
              </form>
            </Box>
          </>
        </>
      )}
    </div>
  );
}

export default App;
