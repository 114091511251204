import React, { useState,useEffect } from 'react';
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  Spinner, 
  useToast ,Heading, Center 
} from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import mainLogo from '../assets/main-logo.png';

const Login = () => {
  const navigate = useNavigate();
  const { number } = useParams();
  const [user_name, setUserName] = useState(number);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSend,setOtpSend]=useState(false)
  const [otp,setOtp]=useState('')
 const [isDisabled, setIsDisabled] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [blockUser, setBlockUser] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (number === undefined) {
      navigate('/user');
      console.log('Number undefined lag rha h');
    } else if (number == 'null') {
      navigate('/user');
      console.log('Number to null h bhai');
    }
  }, []);
  
  const toast = useToast();
    useEffect(() => {
      let interval;
      if (isDisabled) {
        interval = setInterval(() => {
          setTimer(prev => {
            if (prev <= 1) {
              clearInterval(interval);
              setIsDisabled(false);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
      return () => clearInterval(interval);
    }, [isDisabled]);

    useEffect(()=>{
      if(!number){
        toast({
          title: "unauthorised",
          description: "You have successfully logged in.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
        if(localStorage.getItem('token')){
          const user_name = localStorage.getItem('user_name');
          navigate(`/user/dashboard/${user_name}`);
        }
    },[])

    const handleOtp=async (e)=>{
         e.preventDefault();
          if (blockUser) {
             toast({
        title: "Some Error",
        description: "You are blocked from resending OTP. Please try again after 10 minutes.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      
      return;
    }
         const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/user/otp/${number}`);
         setOtpSend(true)
          setResendCount(prev => prev + 1);
    setIsDisabled(true);
    setTimer(30);

    if (resendCount + 1 >= 3) {
      setBlockUser(true);
      setTimeout(() => {
        setBlockUser(false);
        setResendCount(0);
      }, 10 * 60 * 1000); // 10 minutes
    }
    toast({
      title: "OTP",
      description: "Please hold on for a moment while we process your OTP.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/user/login`, {
        user_name,
        password
      });
      localStorage.setItem('token', response.data.data.access_token);
      localStorage.setItem('user_name', response.data.data.user_name);
      navigate(`/user/dashboard/${user_name}`)
      toast({
        title: "Login successful.",
        description: "You have successfully logged in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Handle successful login
    } catch (error) {
      toast({
        title: "Login failed.",
        description: "Invalid username or password.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // Handle login failure
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <div class="logo-box">
      <img src={mainLogo} alt="something went wrong" style={{ height: '70px', margin: '40px auto 0px' }}/>
    </div>
    <Center h="20vh"> {/* Centering content vertically */}
                <Heading as="h1" size="xl" fontWeight="bold">
                    Tetafi User Panel
                </Heading>
            </Center>
              <Box 
      maxW="sm" 
      borderWidth="1px" 
      borderRadius="lg" 
      overflow="hidden" 
      p="6"
      m="auto"
      mt="10"
    >
      <form onSubmit={(e)=>{otpSend?
      
        handleSubmit(e): handleOtp(e)}}>
        <FormControl id="user_name" mb="4">
          <FormLabel>Username</FormLabel>
          <Input 
            type="text" 
            value={user_name}
            // onChange={(e) => setUserName(e.target.value)} 
            disabled
            required 
          />
        </FormControl>
        {otpSend&&  ( <>
        
        <FormControl id="password" mb="4">
          <FormLabel>Otp</FormLabel>
          <Input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </FormControl>
      <div>

      <Button onClick={handleOtp} disabled={isDisabled || blockUser} >
        {isDisabled ? `Resend OTP (${timer}s)` : 'Resend OTP'}
      </Button>
      {blockUser && <p>You have been blocked from resending OTP. Please try again after 10 minutes.</p>}
    </div>
        </>
      
      )
       
        }
     
        <Button type="submit" colorScheme="teal" width="full" mt="4" disabled={loading}>
          {loading ? <Spinner size="sm" /> :otpSend?"Login": 'Otp'}
        </Button>
      </form>
    </Box>
            </>
  
  );
};

export default Login;
