import React from 'react';
import { Bar, Pie } from 'react-chartjs-2'
import axios from 'axios'
import { useState, useEffect } from 'react';
import { Select, Spinner, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import Tabel from '../commons/Tabel';
import {
  Heading,
  Box,
  Text,
  VStack
} from "@chakra-ui/react";
import { saveAs } from 'file-saver';
import { Chart } from 'chart.js';
import { format, subMonths, subWeeks } from 'date-fns';

function AdminDashboardBox() {
  const navigate = useNavigate();
  const dropdown1=["Monthly",'Weekly'];
  const dropdown2=["Expense",'Income','All'];
  const [selectDurationType,setSelectDurationType]=useState(null);
  const [selectExpanseType,setSelectExpanseType]=useState(null);
  const [chartData,setChartData]=useState(null);
  const [reload,setReload]=useState(false);
  const [listReload,setListReload]=useState(false);
  const [chartDataKey,setChartDataKey]=useState("all");
  const [listData,setListData]=useState([]);
  const [inputNumber, setInputNumber] = useState(null);
  const [incomeGoals, setIncomeGoals] = useState(0);
  const [expenseGoals, setExpenseGoals] = useState(0);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [jsonListData, setJsonListData] = useState([]);
  const [label, setLabel] = useState([]);
  const [expenseData, setExpenseData] = useState([100, 3239, 322, 5343]);
  const [incomeData, setIncomeData] = useState([100, 3239, 322, 5343]);
  const [sales, setSales] = useState(0);
  const [costOfSales, setCostOfSales] = useState(0);
  const [rent, setRent] = useState(0);
  const [utilities, setUtilities] = useState(0);
  const [marketing, setMarketing] = useState(0);
  const [equipment, setEquipment] = useState(0);
  const [bankFees, setBankFees] = useState(0);
  const [telephone, setTelephone] = useState(0);
  const [salaries, setSalaries] = useState(0);
  const [travelling, setTravelling] = useState(0);
  const [startUp, setStartUp] = useState(0);
  const [other, setOther] = useState(0);

  const handleButtonClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleItemClick = (item) => {
    fetchDataAndDownloadCSV(item);
    setDropdownVisible(false);
  };

  const fetchDataAndDownloadCSV = async (item) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/${item}`);
      const data = await response.json();
      const csv = convertToCSV(data.data);
      const dateNow = Date.now();
      downloadCSV(csv, `${dateNow}_${item}.csv`);
      setJsonListData([]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const convertToCSV = (data) => {
    const keys = [
      'phone_no',
      'type',
      'amount',
      'expense_goal',
      'expense_p',
      'income_goal',
      'income_p',
      'item',
      'quantity',
      'transaction_type',
      'purpose',
      'category',
      'createdAt'
    ].filter(key => key in data[0]);
  
    const array = [keys];
    data.forEach(item => {
      const row = keys.map(key => item[key] || ''); 
      array.push(row);
    });
  
    return array.map(row => row.join(',')).join('\n');
  };

  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  };

  const data={
    labels: label,
    datasets:[
      {
        label:"Expense",
        data: expenseData,
      },
      {
        label:"Income",
        data: incomeData,
      }
    ]
  };

  useEffect(()=>{
    const token = localStorage.getItem('token');
    if(!token){
      navigate(`/admin/login`);
    } else {
      navigate(`/admin/dashboard`);
    }
  },[]);

  useEffect(()=>{
    setReload(true);
    axios.get(`${process.env.REACT_APP_API_END_POINT}/user/chart/${selectDurationType}/${selectExpanseType}`).then((response)=>{
      setReload(false);
      setChartData(response.data);
    });

    axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/transaction`)
      .then((response)=>{
        setListReload(false);

        setSales(response.data.data
          .filter((data) => data.category === 'sales')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setRent(response.data.data
          .filter((data) => data.category === 'rent')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setMarketing(response.data.data
          .filter((data) => data.category === 'marketing')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setCostOfSales(response.data.data
          .filter((data) => data.category === 'costofsales')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setUtilities(response.data.data
          .filter((data) => data.category === 'utilities')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setEquipment(response.data.data
          .filter((data) => data.category === 'equipment')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setBankFees(response.data.data
          .filter((data) => data.category === 'bank_fees')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setTelephone(response.data.data
          .filter((data) => data.category === 'telephone')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setSalaries(response.data.data
          .filter((data) => data.category === 'salaries')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setTravelling(response.data.data
          .filter((data) => data.category === 'travelling')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setStartUp(response.data.data
          .filter((data) => data.category === 'startup')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );
        
        setOther(response.data.data
          .filter((data) => data.category === 'other')
          .reduce((sum, item) => sum + parseInt(item.amount), 0)
        );

        if (chartDataKey === 'Income') {
          setListData(
            response.data.data.filter((dt) => dt.transaction_type === 'Income')
          );
        } else if (chartDataKey === 'Expense') {
          setListData(
            response.data.data.filter((dt) => dt.transaction_type === 'Expense')
          );
        } else {
          setListData(response.data.data);
        }

        if (selectDurationType === 'Weekly') {
          const weeks = Array.from({ length: 7 }, (_, i) => 
            format(subWeeks(new Date(), i), 'MM/dd/yyyy')
          ).reverse();
          setLabel(weeks);
    
          const expensesData = [];
          const incomesData = [];
          weeks.forEach(week => {
            const expenses = response.data.data
              .filter(dt => dt.transaction_type === 'Expense' && format(new Date(dt.createdAt), 'MM/dd/yyyy') === week)
              .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
            expensesData.push(expenses);
    
            const income = response.data.data
              .filter(dt => dt.transaction_type === 'Income' && format(new Date(dt.createdAt), 'MM/dd/yyyy') === week)
              .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
            incomesData.push(income);
          });
    
          setExpenseData(expensesData);
          setIncomeData(incomesData);
    
        } else {
          const months = Array.from({ length: 7 }, (_, i) => 
            format(subMonths(new Date(), i), 'MMMM')
          ).reverse();
          setLabel(months);
    
          const expensesData = [];
          const incomesData = [];
          months.forEach(month => {
            const expenses = response.data.data
              .filter(dt => dt.transaction_type === 'Expense' && format(new Date(dt.createdAt), 'MMMM') === month)
              .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
            expensesData.push(expenses);
    
            const income = response.data.data
              .filter(dt => dt.transaction_type === 'Income' && format(new Date(dt.createdAt), 'MMMM') === month)
              .reduce((sum, dt) => sum + parseFloat(dt.amount), 0);
            incomesData.push(income);
          });
    
          setExpenseData(expensesData);
          setIncomeData(incomesData);
        }
      });
    },[
      selectDurationType,
      selectExpanseType,
      chartDataKey,
    ]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/goals`).then((response)=>{
      const goalLength = response.data.data.length;
      let totalIncome = 0;
      let totalExpenses = 0;
      response.data.data.map((data) => {
        totalExpenses += data?.expense_goal ? parseInt(data.expense_goal) : 0;
        totalIncome += data?.income_goal ? parseInt(data.income_goal) : 0;
      });
      setIncomeGoals(totalIncome == 0 ? 0 : (totalIncome / goalLength).toFixed(2));
      setExpenseGoals(totalExpenses == 0 ? 0 : (totalExpenses / goalLength).toFixed(2));
    });
  },[]);

  const handleSearch = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_END_POINT}/transactions/transaction/transaction`).then((response)=>{
        const searchData = response.data.data.filter((data) => data.phone_no.startsWith(inputNumber));
        setListData(searchData);
      });
    } catch (err) {
      console.error('Error: ', err);
    }
  }

  const pieChartData = {
    labels: ['Sales', 'Cost of sales', 'Rent', 'Telephone', 'Utilities', 'Marketing', 'Equipment', 'Bank fees', 'Salaries', 'Travelling', 'Start up', 'Other'],
    datasets: [
      {
        label: 'Sum',
        data: [sales, costOfSales, rent, telephone, utilities, marketing, equipment, bankFees, salaries, travelling, startUp, other],
        backgroundColor: [
          'rgba(151, 154, 155, 0.8)',
          'rgba(147, 114, 100, 0.8)',
          'rgba(255, 163, 68, 0.8)',
          'rgba(255, 220, 73, 0.8)',
          'rgba(77, 171, 154, 0.8)',
          'rgba(82, 156, 202, 0.8)',
          'rgba(154, 109, 215, 0.8)',
          'rgba(226, 85, 161, 0.8)',
          'rgba(255, 115, 105, 0.8)',
          'rgba(218, 112, 214, 0.8)',
          'rgba(255, 215, 0, 0.8)',
          'rgba(255, 140, 0, 0.8)',
        ],
        borderColor: [
          'rgba(151, 154, 155, 1)',
          'rgba(147, 114, 100, 1)',
          'rgba(255, 163, 68, 1)',
          'rgba(255, 220, 73, 1)',
          'rgba(77, 171, 154, 1)',
          'rgba(82, 156, 202, 1)',
          'rgba(154, 109, 215, 1)',
          'rgba(226, 85, 161, 1)',
          'rgba(255, 115, 105, 1)',
          'rgba(218, 112, 214, 1)',
          'rgba(255, 215, 0, 1)',
          'rgba(255, 140, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const months = Array.from({ length: 7 }, (_, i) => 
      format(subMonths(new Date(), i), 'MMMM')
    ).reverse();
    setLabel(months);
  }, []);

  return (
    <div>
      <hr
        style={{
          height: "2px",
          width: "100%",
          marginBottom: "10px",
          color: "darkgray",
        }}
      />
      <div>
        <input
          type='number'
          placeholder='Enter number'
          style={{
            padding: '5px 10px',
            borderRadius: '4px',
            border: '1px solid grey',
          }}
          value={inputNumber}
          onChange={(e) => setInputNumber(e.target.value)}
        />
        <button
          style={{
            marginLeft: '10px',
            padding: '5px 10px',
            borderRadius: '4px',
            border: '1px solid grey',
          }}
          onClick={handleSearch}d
        >Search</button>

        <button
          style={{
            marginLeft: '10px',
            padding: '5px 10px',
            borderRadius: '4px',
            border: '1px solid grey',
          }}
          onClick={handleButtonClick}
        >Download CSV</button>
      </div>

      {isDropdownVisible && (
        <div
          style={{
            position: 'absolute',
            left: '30%',
            top: '25%',
            backgroundColor: 'white',
            border: '1px solid grey',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
          }}
        >
          <div
            style={{ padding: '8px 12px', cursor: 'pointer' }}
            onClick={() => handleItemClick('transaction')}
          >
            Transactions
          </div>
          <div
            style={{ padding: '8px 12px', cursor: 'pointer' }}
            onClick={() => handleItemClick('invoice')}
          >
            Invoices
          </div>
          <div
            style={{ padding: '8px 12px', cursor: 'pointer' }}
            onClick={() => handleItemClick('goals')}
          >
            Goals
          </div>
        </div>
      )}
      <div
        style={{
          padding: "8px 20px 24px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack spacing={3} w='14vw'>
          <Select placeholder='Select Duration' size='md'
            onChange={(e)=>{
              setSelectDurationType(e.target.value === 'Monthly' ? 'Monthly' : 'Weekly');
            }}
            style={{ width: "200px", padding: "0px 10px", height: "50px", margin: "0px 0px 0px"}}
          >
            {dropdown1.map((item)=>(
              <option value={item}>{item}</option>
            ))}
          </Select>
        </Stack>

        <Stack spacing={3} w='14vw'>
          <Select placeholder='Select Transaction' size='md'
            onChange={(e)=>{
              setSelectExpanseType(e.target.value==="Expense" ? 'Expense' : e.target.value ==="Income"  ? 'Income' : 'All');
              setChartDataKey(e.target.value==="Expense" ? 'Expense' : e.target.value ==="Income"  ? 'Income' : 'all');
            }}
            style={{ width: "200px", padding: "0px 10px", height: "50px", margin: "0px 0px 0px"}}
          >
            {dropdown2.map((item)=>(
              <option value={item}>{item}</option>
            ))}
          </Select>
        </Stack>
      </div>

      {
        reload ?(<>
        <div className='flex flex-col items-center justify-center h-screen w-screen '>
          <Spinner size="sm" /> 
        </div>
        </>) : (<>
        {chartData && <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: 3 }}>
              <Bar data={data} />
            </div>
            <div style={{ flex: 1, margin: 'auto 10px' }}>
              <Pie data={pieChartData} />
            </div>
          </div>
        }
        </>)
      }

      <div className="my-5">
        <Box p={5} maxW="xl" borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="lg" mx="auto" my={5}>
          <Heading as="h4" size="lg" mb={8}>
            Your Goals
          </Heading>
          <VStack align="start">
            <Box display="flex" justifyContent="space-between">
              <Box mx={5}>
                <Text fontSize="md" fontWeight="bold">
                  Average Expense Goal
                </Text>
                <Text fontSize="sm">R {expenseGoals}</Text>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="bold">
                  Average Income Goal
                </Text>
                <Text fontSize="sm">R {incomeGoals}</Text>
              </Box>
            </Box>
          </VStack>
        </Box>
      </div>

      <div my={5}>
        <Tabel listData={listData} header_data={[ 'phone_no', 'transaction_type','amount','category','purpose' ]} listReload={listReload}/>
      </div>
    </div>
  );
}

export default AdminDashboardBox;

